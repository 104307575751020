<template>
  <div>
    <template>
      <div :dir="$t('clan') == 'ar' ? 'rtl' : 'ltr'">
        <div class="rows">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("Clients") }}</h3>
                </div>
                <span class="add">
                  <router-link to="/admin/clients/create">
                    <b-button variant="warning"
                      ><i
                        class="fas fa-plus-circle text-white mr-2"
                        v-if="$t('clan') == 'en'"
                      ></i
                      ><i class="fas fa-plus-circle text-white ml-2" v-else></i
                      >{{ $t("New") }}</b-button
                    >
                  </router-link>
                </span>
              </div>
              <div class="card-body">
                <div>
                  
                  <ClientsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
 

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClientsTable from "@/view/pages/Clients/ClientsTable.vue";

export default {
  name: "Clients",

  components: { ClientsTable },

  data() {
    return {
      
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Clients", route: "/admin/clients" },
    ]);
    //Loads in layout to reduce the delay
  },
  methods: {
    
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}

</style>