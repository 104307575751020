<template>
  <div>
    <div class="form-group">
                    <div class="input-icon input-icon-right">
                      <input
                        type="text"
                        class="form-control"
                        @input="search"
                        v-model="filter"
                        placeholder="Search by name or phone"
                      />
                      <span @click="search"
                        ><i class="flaticon2-search-1 icon-md"></i
                      ></span>
                    </div>
                  </div>
    <b-table
      :items="table.items"
      :fields="$t('clan')=='en'?table.fields:tableAR.fields"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
    >

    <template #empty>
              <div v-if="isBusy" class="text-center ">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinnerClass"
                ></b-spinner>
              </div>
              <p
                v-else

                class="text-center muted mb-0 mt-2"
                style="font-size: 13px"
              >
                {{ $t('There are no records to show') }}
              </p>
            </template>

      <template #cell(actions)="row">
        <div class="">
          <button @click.prevent="viewClient(row.item.id)" class="">
            <i class="far fas fa-eye"></i>
            {{$t('view')}}
          </button>

           <button
              @click.prevent="showModal(row.item.id)"
              class="ml-4"
            >
              <i class="fas fa-trash mr-2"></i>{{$t('Delete')}}
            </button>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <!--<b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="left"
      size="lg"
      class="my-0"
      @click.native="getData"
    ></b-pagination> -->

    <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>

  <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="$t('deleteCl')"
      hide-footer
    >
      <p>
        {{$t('delClient')}}
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >{{$t('cancel')}}</b-button
        >
        <b-button variant="danger" @click="deleteClient">{{$t('Delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ClientsTable",
  data: function () {
    return {
      isBusy:'',
      filter: "",
      totalRows: 1,
      currentPage: 1,
      perPage: '',
      firstPage:1,
      lastPage:'',
      client:{
        id:''
      },
      user:{
        id:''
      }
    };
  },

  computed: {
    ...mapGetters({ table: "clients/table" }),
    ...mapGetters({ tableAR: "clients/tableAR" }),
  },
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("clients/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      this.perPage = res.data.per_page
      this.isBusy = false
    });
    localStorage.setItem('page',this.currentPage)
    localStorage.setItem('lastPage',this.lastPage)
  },

  methods: {
    search() {
      this.$store.dispatch("clients/search", this.filter).then((res)=>{
        this.currentPage = res.data.current_page
        this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      });
      
    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      localStorage.setItem('page',this.currentPage)
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
       localStorage.setItem('page',this.currentPage)
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("clients/loadPage", this.currentPage);
      this.isBusy = false
    },
    showModal(id) {
      this.$refs["my-modal"].show();
      this.client.id = id            
      
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    viewClient(id) {
      this.$router.push({ name: "ViewClient", params: { id: id } });
    },
    async deleteClient(){

      await this.$store.dispatch('clients/delete', this.client)

    }
  },
};
</script>
<style lang="css" scoped>
.icon-md:hover {
  color: #4f2c85 !important;
  cursor: pointer;
}
</style>